<template>
  <div class="container-fluid bg-dark py-3 px-lg-5 d-none d-lg-block">
    <div class="row">
      <div class="col-md-6 text-center text-lg-left mb-2 mb-lg-0">
        <div class="d-inline-flex align-items-center">
          <a class="text-body pr-3" href=""
            ><i class="fa fa-phone-alt mr-2"></i>0789326245</a
          >
          <span class="text-body">|</span>
          <a class="text-body px-3" href=""
            ><i class="fa fa-envelope mr-2"></i>info@example.com</a
          >
        </div>
      </div>
      <div class="col-md-6 text-center text-lg-right">
        <div class="d-inline-flex align-items-center">
          <a class="text-body px-3" href="">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a class="text-body px-3" href="">
            <i class="fab fa-twitter"></i>
          </a>
          <a class="text-body px-3" href="">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a class="text-body px-3" href="">
            <i class="fab fa-instagram"></i>
          </a>
          <a class="text-body pl-3" href="">
            <i class="fab fa-youtube"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Topbar End -->

  <div class="divnavheader">
    <!-- Navbar Start -->
  <div class="container-fluid position-relative nav-bar p-0">
    <div class="position-relative px-lg-5" style="z-index: 9">
      <nav
        class="navbar navbar-expand-lg bg-secondary navbar-dark py-3 py-lg-0 pl-3 pl-lg-5"
      >
        <a href="" class="navbar-brand">
          <h1 class="text-uppercase text-primary mb-1">Access Transport Company</h1>
        </a>
        <button
          type="button"
          class="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarCollapse"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-between px-3"
          id="navbarCollapse"
        >
          <div class="navbar-nav ml-auto py-0">
            <a href="/" class="nav-item nav-link active">Home</a>
            <a href="/about" class="nav-item nav-link">About</a>
            <a href="/service" class="nav-item nav-link">Service</a>
            <div class="nav-item dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-toggle="dropdown"
                >Cars</a
              >
              <div class="dropdown-menu rounded-0 m-0">
                <a href="/cars" class="dropdown-item">Car Booking</a>
              </div>
            </div>
            <a href="/contact" class="nav-item nav-link">Contact</a>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- Navbar End -->

  <!-- Search Start -->
  <div class="container-fluid bg-white pt-3 px-lg-5">
    <div class="row mx-n2">
      <div class="col-xl-2 col-lg-4 col-md-6 px-2">
        <select class="custom-select px-4 mb-3" style="height: 50px">
          <option selected>Pickup Location</option>
          <option value="1">Location 1</option>
          <option value="2">Location 2</option>
          <option value="3">Location 3</option>
        </select>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-6 px-2">
        <select class="custom-select px-4 mb-3" style="height: 50px">
          <option selected>Drop Location</option>
          <option value="1">Location 1</option>
          <option value="2">Location 2</option>
          <option value="3">Location 3</option>
        </select>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-6 px-2">
        <div class="date mb-3" id="date" data-target-input="nearest">
          <input
            type="date"
            class="form-control p-4 datetimepicker-input"
            placeholder="Pickup Date"
          />
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-6 px-2">
        <div class="time mb-3" id="time" data-target-input="nearest">
          <input
            type="time"
            class="form-control p-4 datetimepicker-input"
            placeholder="Pickup Time"
          />
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-6 px-2">
        <select class="custom-select px-4 mb-3" style="height: 50px">
          <option selected>Select A Car</option>
          <option value="1">Car 1</option>
          <option value="2">Car 1</option>
          <option value="3">Car 1</option>
        </select>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-6 px-2">
        <button
          class="btn btn-primary btn-block mb-3"
          type="submit"
          style="height: 50px"
        >
          Search
        </button>
      </div>
    </div>
  </div>
  <!-- Search End -->
  </div>

  <!-- Carousel Start -->
  <div class="container-fluid p-0" style="margin-bottom: 90px">
    <div id="header-carousel" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="w-100" src="img/carousel-1.jpg" alt="Image" />
          <div
            class="carousel-caption d-flex flex-column align-items-center justify-content-center"
          >
            <div class="p-3" style="max-width: 900px">
              <h4 class="text-white text-uppercase mb-md-3">Rent A Car</h4>
              <h1 class="display-1 text-white mb-md-4">
                Best Rental Cars In Kigali
              </h1>
              <a href="" class="btn btn-primary py-md-3 px-md-5 mt-2"
                >Reserve Now</a
              >
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img class="w-100" src="img/carousel-2.jpg" alt="Image" />
          <div
            class="carousel-caption d-flex flex-column align-items-center justify-content-center"
          >
            <div class="p-3" style="max-width: 900px">
              <h4 class="text-white text-uppercase mb-md-3">Rent A Car</h4>
              <h1 class="display-1 text-white mb-md-4">
                Quality Cars with Unlimited Miles
              </h1>
              <a href="" class="btn btn-primary py-md-3 px-md-5 mt-2"
                >Reserve Now</a
              >
            </div>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#header-carousel"
        data-slide="prev"
      >
        <div class="btn btn-dark" style="width: 45px; height: 45px">
          <span class="carousel-control-prev-icon mb-n2"></span>
        </div>
      </a>
      <a
        class="carousel-control-next"
        href="#header-carousel"
        data-slide="next"
      >
        <div class="btn btn-dark" style="width: 45px; height: 45px">
          <span class="carousel-control-next-icon mb-n2"></span>
        </div>
      </a>
    </div>
  </div>
  <!-- Carousel End -->

  <!-- About Start -->
  <div class="container-fluid py-5">
    <div class="container pt-5 pb-3">
      <!-- <h1 class="display-1 text-primary text-center">01</h1> -->
      <h1 class="display-4 text-uppercase text-center mb-5">
        Welcome To <span class="text-primary">Access Transport</span>
      </h1>
      <div class="row justify-content-center">
        <div class="col-lg-10 text-center">
          <img class="w-75 mb-4" src="img/about.png" alt="" />
          <!-- <p>
            Justo et eos et ut takimata sed sadipscing dolore lorem, et elitr
            labore labore voluptua no rebum sed, stet voluptua amet sed elitr ea
            dolor dolores no clita. Dolores diam magna clita ea eos amet, amet
            rebum voluptua vero vero sed clita accusam takimata. Nonumy labore
            ipsum sea voluptua sea eos sit justo, no ipsum sanctus sanctus no et
            no ipsum amet, tempor labore est labore no. Eos diam eirmod lorem ut
            eirmod, ipsum diam sadipscing stet dolores elitr elitr eirmod
            dolore. Magna elitr accusam takimata labore, et at erat eirmod
            consetetur tempor eirmod invidunt est, ipsum nonumy at et.
          </p> -->
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-4 mb-2">
          <div
            class="d-flex align-items-center bg-light p-4 mb-4"
            style="height: 150px"
          >
            <div
              class="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary ml-n4 mr-4"
              style="width: 100px; height: 100px"
            >
              <i class="fa fa-2x fa-headset text-secondary"></i>
            </div>
            <h4 class="text-uppercase m-0">24/7 Car Rental Support</h4>
          </div>
        </div>
        <div class="col-lg-4 mb-2">
          <div
            class="d-flex align-items-center bg-secondary p-4 mb-4"
            style="height: 150px"
          >
            <div
              class="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary ml-n4 mr-4"
              style="width: 100px; height: 100px"
            >
              <i class="fa fa-2x fa-car text-secondary"></i>
            </div>
            <h4 class="text-light text-uppercase m-0">
              Car Reservation Anytime
            </h4>
          </div>
        </div>
        <div class="col-lg-4 mb-2">
          <div
            class="d-flex align-items-center bg-light p-4 mb-4"
            style="height: 150px"
          >
            <div
              class="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary ml-n4 mr-4"
              style="width: 100px; height: 100px"
            >
              <i class="fa fa-2x fa-map-marker-alt text-secondary"></i>
            </div>
            <h4 class="text-uppercase m-0">Lots Of Pickup Locations</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->
  <!-- Rent A Car Start -->
  <div class="container-fluid py-5">
    <div class="container pt-5 pb-3">
      <!-- <h1 class="display-1 text-primary text-center">03</h1> -->
      <h1 class="display-4 text-uppercase text-center mb-5">Find Your Car</h1>
      <div class="row">
        <div class="col-lg-4 col-md-6 mb-2" @click="carDetails()">
          <div class="rent-item mb-4">
            <img class="img-fluid mb-4" src="img/car-rent-1.png" alt="" />
            <h4 class="text-uppercase mb-4">Mercedes Benz R3</h4>
            <div class="d-flex justify-content-center mb-4">
              <div class="px-2">
                <i class="fa fa-car text-primary mr-1"></i>
                <span>2015</span>
              </div>
              <div class="px-2 border-left border-right">
                <i class="fa fa-cogs text-primary mr-1"></i>
                <span>AUTO</span>
              </div>
              <div class="px-2">
                <i class="fa fa-road text-primary mr-1"></i>
                <span>25K</span>
              </div>
            </div>
            <a class="btn btn-primary px-3" href="/car-details">RWF 5OK</a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-2" @click="carDetails()">
          <div class="rent-item active mb-4">
            <img class="img-fluid mb-4" src="img/car-rent-2.png" alt="" />
            <h4 class="text-uppercase mb-4">Mercedes Benz R3</h4>
            <div class="d-flex justify-content-center mb-4">
              <div class="px-2">
                <i class="fa fa-car text-primary mr-1"></i>
                <span>2015</span>
              </div>
              <div class="px-2 border-left border-right">
                <i class="fa fa-cogs text-primary mr-1"></i>
                <span>AUTO</span>
              </div>
              <div class="px-2">
                <i class="fa fa-road text-primary mr-1"></i>
                <span>25K</span>
              </div>
            </div>
            <a class="btn btn-primary px-3" href="/car-details">RWF 5OK</a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-2" @click="carDetails()">
          <div class="rent-item mb-4">
            <img class="img-fluid mb-4" src="img/car-rent-3.png" alt="" />
            <h4 class="text-uppercase mb-4">Mercedes Benz R3</h4>
            <div class="d-flex justify-content-center mb-4">
              <div class="px-2">
                <i class="fa fa-car text-primary mr-1"></i>
                <span>2015</span>
              </div>
              <div class="px-2 border-left border-right">
                <i class="fa fa-cogs text-primary mr-1"></i>
                <span>AUTO</span>
              </div>
              <div class="px-2">
                <i class="fa fa-road text-primary mr-1"></i>
                <span>25K</span>
              </div>
            </div>
            <a class="btn btn-primary px-3" href="/car-details">RWF 5OK</a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-2" @click="carDetails()">
          <div class="rent-item mb-4">
            <img class="img-fluid mb-4" src="img/car-rent-4.png" alt="" />
            <h4 class="text-uppercase mb-4">Mercedes Benz R3</h4>
            <div class="d-flex justify-content-center mb-4">
              <div class="px-2">
                <i class="fa fa-car text-primary mr-1"></i>
                <span>2015</span>
              </div>
              <div class="px-2 border-left border-right">
                <i class="fa fa-cogs text-primary mr-1"></i>
                <span>AUTO</span>
              </div>
              <div class="px-2">
                <i class="fa fa-road text-primary mr-1"></i>
                <span>25K</span>
              </div>
            </div>
            <a class="btn btn-primary px-3" href="/car-details">RWF 5OK</a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-2" @click="carDetails()">
          <div class="rent-item mb-4">
            <img class="img-fluid mb-4" src="img/car-rent-5.png" alt="" />
            <h4 class="text-uppercase mb-4">Mercedes Benz R3</h4>
            <div class="d-flex justify-content-center mb-4">
              <div class="px-2">
                <i class="fa fa-car text-primary mr-1"></i>
                <span>2015</span>
              </div>
              <div class="px-2 border-left border-right">
                <i class="fa fa-cogs text-primary mr-1"></i>
                <span>AUTO</span>
              </div>
              <div class="px-2">
                <i class="fa fa-road text-primary mr-1"></i>
                <span>25K</span>
              </div>
            </div>
            <a class="btn btn-primary px-3" href="/car-details">RWF 5OK</a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-2" @click="carDetails()">
          <div class="rent-item mb-4">
            <img class="img-fluid mb-4" src="img/car-rent-6.png" alt="" />
            <h4 class="text-uppercase mb-4">Mercedes Benz R3</h4>
            <div class="d-flex justify-content-center mb-4">
              <div class="px-2">
                <i class="fa fa-car text-primary mr-1"></i>
                <span>2015</span>
              </div>
              <div class="px-2 border-left border-right">
                <i class="fa fa-cogs text-primary mr-1"></i>
                <span>AUTO</span>
              </div>
              <div class="px-2">
                <i class="fa fa-road text-primary mr-1"></i>
                <span>25K</span>
              </div>
            </div>
            <a class="btn btn-primary px-3" href="/car-details">RWF 5OK</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Rent A Car End -->

  <!-- Banner Start -->
  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="bg-banner py-5 px-4 text-center">
        <div class="py-5">
          <h1 class="display-1 text-uppercase text-primary mb-4">50% OFF</h1>
          <h1 class="text-uppercase text-light mb-4">
            Special Offer For New Members
          </h1>
          <p class="mb-4">Only for Sunday from 1st Jan to 30th Jan 2045</p>
          <a class="btn btn-primary mt-2 py-3 px-5" href="">Register Now</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Banner End -->

  <!-- Banner Start -->
  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="row mx-0">
        <div class="col-lg-6 px-0">
          <div
            class="px-5 bg-secondary d-flex align-items-center justify-content-between"
            style="height: 350px"
          >
            <img
              class="img-fluid flex-shrink-0 ml-n5 w-50 mr-4"
              src="img/banner-left.png"
              alt=""
            />
            <div class="text-right">
              <h3 class="text-uppercase text-light mb-3">Want to be driver?</h3>
              <p class="mb-4">
                Lorem justo sit sit ipsum eos lorem kasd, kasd labore
              </p>
              <a class="btn btn-primary py-2 px-4" href="">Start Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 px-0">
          <div
            class="px-5 bg-dark d-flex align-items-center justify-content-between"
            style="height: 350px"
          >
            <div class="text-left">
              <h3 class="text-uppercase text-light mb-3">Looking for a car?</h3>
              <p class="mb-4">
                Lorem justo sit sit ipsum eos lorem kasd, kasd labore
              </p>
              <a class="btn btn-primary py-2 px-4" href="">Start Now</a>
            </div>
            <img
              class="img-fluid flex-shrink-0 mr-n5 w-50 ml-4"
              src="img/banner-right.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Banner End -->

  <!-- Contact Start -->
  <div class="container-fluid py-5">
    <div class="container pt-5 pb-3">
      <!-- <h1 class="display-1 text-primary text-center">06</h1> -->
      <h1 class="display-4 text-uppercase text-center mb-5">Contact Us</h1>
      <div class="row">
        <div class="col-lg-7 mb-2">
          <div class="contact-form bg-light mb-4" style="padding: 30px">
            <form>
              <div class="row">
                <div class="col-6 form-group">
                  <input
                    type="text"
                    class="form-control p-4"
                    placeholder="Your Name"
                    required="required"
                  />
                </div>
                <div class="col-6 form-group">
                  <input
                    type="email"
                    class="form-control p-4"
                    placeholder="Your Email"
                    required="required"
                  />
                </div>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control p-4"
                  placeholder="Subject"
                  required="required"
                />
              </div>
              <div class="form-group">
                <textarea
                  class="form-control py-3 px-4"
                  rows="5"
                  placeholder="Message"
                  required="required"
                ></textarea>
              </div>
              <div>
                <button class="btn btn-primary py-3 px-5" type="submit">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-5 mb-2">
          <div
            class="bg-secondary d-flex flex-column justify-content-center px-5 mb-4"
            style="height: 435px"
          >
            <div class="d-flex mb-3">
              <i
                class="fa fa-2x fa-map-marker-alt text-primary flex-shrink-0 mr-3"
              ></i>
              <div class="mt-n1">
                <h5 class="text-light">Head Office</h5>
                <p>123 Street, Kigali, RWANDA</p>
              </div>
            </div>
            <div class="d-flex mb-3">
              <i
                class="fa fa-2x fa-map-marker-alt text-primary flex-shrink-0 mr-3"
              ></i>
              <div class="mt-n1">
                <h5 class="text-light">Branch Office</h5>
                <p>123 Street, Kigali, RWANDA</p>
              </div>
            </div>
            <div class="d-flex mb-3">
              <i
                class="fa fa-2x fa-envelope-open text-primary flex-shrink-0 mr-3"
              ></i>
              <div class="mt-n1">
                <h5 class="text-light">Customer Service</h5>
                <p>customer@example.com</p>
              </div>
            </div>
            <div class="d-flex">
              <i
                class="fa fa-2x fa-envelope-open text-primary flex-shrink-0 mr-3"
              ></i>
              <div class="mt-n1">
                <h5 class="text-light">Return & Refund</h5>
                <p class="m-0">refund@example.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Contact End -->

  <!-- Vendor Start -->
  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="owl-carousel vendor-carousel">
        <div class="bg-light p-4">
          <img src="img/vendor-1.png" alt="" />
        </div>
        <div class="bg-light p-4">
          <img src="img/vendor-2.png" alt="" />
        </div>
        <div class="bg-light p-4">
          <img src="img/vendor-3.png" alt="" />
        </div>
        <div class="bg-light p-4">
          <img src="img/vendor-4.png" alt="" />
        </div>
        <div class="bg-light p-4">
          <img src="img/vendor-5.png" alt="" />
        </div>
        <div class="bg-light p-4">
          <img src="img/vendor-6.png" alt="" />
        </div>
        <div class="bg-light p-4">
          <img src="img/vendor-7.png" alt="" />
        </div>
        <div class="bg-light p-4">
          <img src="img/vendor-8.png" alt="" />
        </div>
      </div>
    </div>
  </div>
  <!-- Vendor End -->

  <!-- Footer Start -->
  <div
    class="container-fluid bg-secondary py-5 px-sm-3 px-md-5"
    style="margin-top: 90px"
  >
    <div class="row pt-5">
      <div class="col-lg-3 col-md-6 mb-5">
        <h4 class="text-uppercase text-light mb-4">Get In Touch</h4>
        <p class="mb-2">
          <i class="fa fa-map-marker-alt text-white mr-3"></i>123 Street,
          Kigali, RWANDA
        </p>
        <p class="mb-2">
          <i class="fa fa-phone-alt text-white mr-3"></i>+012 345 67890
        </p>
        <p><i class="fa fa-envelope text-white mr-3"></i>info@example.com</p>
        <h6 class="text-uppercase text-white py-2">Follow Us</h6>
        <div class="d-flex justify-content-start">
          <a class="btn btn-lg btn-dark btn-lg-square mr-2" href="#"
            ><i class="fab fa-twitter"></i
          ></a>
          <a class="btn btn-lg btn-dark btn-lg-square mr-2" href="#"
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a class="btn btn-lg btn-dark btn-lg-square mr-2" href="#"
            ><i class="fab fa-linkedin-in"></i
          ></a>
          <a class="btn btn-lg btn-dark btn-lg-square" href="#"
            ><i class="fab fa-instagram"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5">
        <h4 class="text-uppercase text-light mb-4">Usefull Links</h4>
        <div class="d-flex flex-column justify-content-start">
          <a class="text-body mb-2" href="#"
            ><i class="fa fa-angle-right text-white mr-2"></i>Private Policy</a
          >
          <a class="text-body mb-2" href="#"
            ><i class="fa fa-angle-right text-white mr-2"></i>Term &
            Conditions</a
          >
          <a class="text-body mb-2" href="#"
            ><i class="fa fa-angle-right text-white mr-2"></i>New Member
            Registration</a
          >
          <a class="text-body mb-2" href="#"
            ><i class="fa fa-angle-right text-white mr-2"></i>Affiliate
            Programme</a
          >
          <a class="text-body mb-2" href="#"
            ><i class="fa fa-angle-right text-white mr-2"></i>Return & Refund</a
          >
          <a class="text-body" href="#"
            ><i class="fa fa-angle-right text-white mr-2"></i>Help & FQAs</a
          >
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5">
        <h4 class="text-uppercase text-light mb-4">Car Gallery</h4>
        <div class="row mx-n1">
          <div class="col-4 px-1 mb-2">
            <a href=""><img class="w-100" src="img/gallery-1.jpg" alt="" /></a>
          </div>
          <div class="col-4 px-1 mb-2">
            <a href=""><img class="w-100" src="img/gallery-2.jpg" alt="" /></a>
          </div>
          <div class="col-4 px-1 mb-2">
            <a href=""><img class="w-100" src="img/gallery-3.jpg" alt="" /></a>
          </div>
          <div class="col-4 px-1 mb-2">
            <a href=""><img class="w-100" src="img/gallery-4.jpg" alt="" /></a>
          </div>
          <div class="col-4 px-1 mb-2">
            <a href=""><img class="w-100" src="img/gallery-5.jpg" alt="" /></a>
          </div>
          <div class="col-4 px-1 mb-2">
            <a href=""><img class="w-100" src="img/gallery-6.jpg" alt="" /></a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5">
        <h4 class="text-uppercase text-light mb-4">Newsletter</h4>
        <p class="mb-4">
          Volup amet magna clita tempor. Tempor sea eos vero ipsum. Lorem lorem
          sit sed elitr sed kasd et
        </p>
        <div class="w-100 mb-3">
          <div class="input-group">
            <input
              type="text"
              class="form-control bg-dark border-dark"
              style="padding: 25px"
              placeholder="Your Email"
            />
            <div class="input-group-append">
              <button class="btn btn-primary text-uppercase px-3">
                Sign Up
              </button>
            </div>
          </div>
        </div>
        <i>Lorem sit sed elitr sed kasd et</i>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-dark py-4 px-sm-3 px-md-5">
    <p class="mb-2 text-center text-body">
      &copy; <a href="#">Access Transport</a>. All Rights Reserved.
    </p>
    <p class="m-0 text-center text-body">
      Designed by <a href="#">Feli Technology</a>
    </p>
  </div>
  <!-- Footer End -->

  <!-- Back to Top -->
  <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"
    ><i class="fa fa-angle-double-up"></i
  ></a>
</template>
<script>
export default {
  methods: {
    carDetails () {
      window.location.href = '/car-details'
    }
  }
}
</script>
